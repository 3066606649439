var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"78b3072741e9ac22bef2c6ef55dd9c119d77d1f4"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,
  // Note regarding `ignoreErrors`: When using strings, they'll partially match the
  // messages, so if you need to achieve an exact match use RegExp patterns instead.
  ignoreErrors: [
    'AbortError: The user aborted a request.',
    'cancelled',
    'Failed to fetch',
    'NetworkError when attempting to fetch resource.',
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
  ],
  enabled: process.env.NEXT_PUBLIC_APPLICATION_ENVIRONMENT === 'production',
});
